<template>
  <v-container>
    <!-- <v-toolbar flat> -->
    <!-- <v-spacer></v-spacer> -->
    <!-- <icon-btn v-if="user.permissions.event.delete" tooltip="Usuń" icon="mdi-delete" color="critical"
        @click="onDeleteEvent" /> -->
    <!-- <icon-btn :visible="canCloseTask" tooltip="Powiadom" icon="mdi-account-alert-outline" @click="notifyUsers" /> -->
    <!-- <icon-btn :visible="canCloseTask" tooltip="Wyślij patrol" icon="mdi-car-outline" @click="startPatrolTask" /> -->
    <!-- <icon-btn :visible="canCloseTask" tooltip="Anulowane przez klienta" icon="mdi-account-cancel-outline"
        @click="cancelTask" /> -->
    <!-- <icon-btn :visible="canCloseTask" tooltip="Zakończ obsługę" icon="mdi-progress-check" @click="closeAllTasks" /> -->
    <!-- <icon-btn :visible="canOpenTask(dispatcherTask)" tooltip="Rozpocznij obsługę" icon="mdi-progress-star"
        @click="startDispatcherTask" /> -->
    <!-- <v-btn v-if="canCloseTask" icon @click="startPatrolTask">
        <v-icon>mdi-car</v-icon>
      </v-btn> -->
    <!-- <v-btn v-if="canCloseTask" icon @click="closePatrolTask">
        <v-icon>mdi-bell-off</v-icon>
      </v-btn> -->
    <!--       <v-btn icon @click="canCloseTask = !canCloseTask">
        <v-icon>mdi-mail</v-icon>
      </v-btn> -->
    <!-- </v-toolbar> -->
    <site-notes :notes="notes" :site="event.site" :locked="false" />
    <v-row>
      <v-col cols="12" lg="6">
        <material-card outlined color="white" class="pa-0 fill-height">
          <template v-slot:heading>
            <task-status-chip v-if="dispatcherTask != null" large
              :status="dispatcherTask.details.status"></task-status-chip>
          </template>
          <template #title>
            <v-hover v-slot:default="{ hover }">
              <v-row dense align="center">
                <div>
                  <div>{{ event.code | codeAsString }} <span class="text-caption grey--text">
                      {{ event.created_at | dateMediumTimeShort }}
                    </span></div>

                  <div class="text-subtitle-2">{{ eventInfo }}</div>
                  <site-link :site="event.site"></site-link>
                </div>
                <icon-btn v-if="hover && canEditEvent" small tooltip="Edytuj zdarzenie" icon="mdi-pencil"
                  @click="onEditEvent" />
              </v-row>
            </v-hover>
          </template>
          <!-- <v-card-text class="py-0">
            <v-row align="center">
              <v-col cols="12" align="center">
                <site-link :site="event.site"></site-link>
                <site-address class="ml-0" :site="event.site"></site-address>
              </v-col>
            </v-row>
            <div class="note text-caption-2 mt-2">{{ dispatcherTaskComment }}</div>
          </v-card-text> -->
          <v-card-text>
            <task-timeline :event="event" :task="dispatcherTask" :site="event.site" :readOnly="!canCloseTask" />
            <v-textarea dense v-model='comment' append-outer-icon='mdi-send' @click:append-outer="addComment" clearable
              autofocus filled type="text" @click:clear="comment = null" v-if="canCloseTask" outlined label="Komentarz"
              @keydown.enter.exact.prevent="addComment" @keydown.enter.shift.exact.prevent="addLineToComment" rows="1"
              auto-grow hide-details="auto" />
            <!-- <v-textarea class="mt-2" label="Dodatkowy komentarz ( Shift+Enter : nowa linia )" v-model="result.comment" rows="1" auto-grow
              @keydown.enter.exact.prevent="onEnter" @keydown.enter.shift.exact.prevent="result.comment += '\n'">
            </v-textarea> -->
          </v-card-text>
          <template #actions>
            <!-- <div class="">{{ statusText }}</div>
            <div class="black--text">
              {{ event.created_at | dateMediumTimeShort }}
            </div> -->
            <v-spacer />
            <toolbar-btn icon="mdi-cloud-download-outline" tooltip="Zapis do pliku" @click="onDownload()" />
            <icon-btn v-if="canCloseTask" tooltip="Opłata" icon="mdi-currency-usd" @click="setPayment"></icon-btn>
            <!-- <icon-btn v-if="canEditEvent" tooltip="Edytuj zdarzenie" icon="mdi-pencil" @click="onEditEvent" /> -->
            <icon-btn v-if="user.permissions.event.delete" tooltip="Usuń" icon="mdi-delete" color="critical"
              @click="onDeleteEvent" />
            <icon-btn :visible="canCloseTask" tooltip="Osoba w obiekcie" icon="mdi-account-check-outline"
              @click="foundUsers" />
            <icon-btn :visible="canCloseTask" tooltip="Powiadom" icon="mdi-account-alert-outline" @click="notifyUsers" />
            <icon-btn :visible="canCloseTask" tooltip="Wyślij patrol" icon="mdi-car-outline" @click="startPatrolTask" />
            <icon-btn :visible="canCloseTask" tooltip="Anulowane przez klienta" icon="mdi-account-cancel-outline"
              @click="cancelTask" />
            <icon-btn :visible="canCloseTask" tooltip="Zakończ obsługę" icon="mdi-progress-check"
              @click="closeAllTasks" />
            <icon-btn :visible="canOpenTask(dispatcherTask)" tooltip="Rozpocznij obsługę" icon="mdi-progress-star"
              @click="startDispatcherTask" />
          </template>
        </material-card>
      </v-col>
      <!-- <v-col cols="12" lg="6" class="">
        <material-card outlined class="fill-height" icon="mdi-account-group">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" v-if="eventCreated" class="black--text font-weight-medium">
                Utworzył:
                <v-chip class="ma-2" small>
                  <v-icon left small> mdi-account-plus-outline </v-icon>
                  {{ eventCreated }}
                </v-chip>
              </v-col>

              <v-col cols="12" v-if="eventReported" class="black--text font-weight-medium">
                Zgłosił:
                <v-chip class="ma-2" small>
                  <v-icon left small> mdi-account-alert-outline </v-icon>
                  {{ eventReported }}
                </v-chip>
              </v-col>
              <v-col cols="12" v-if="notifiedUsers.length" class="black--text font-weight-medium">
                Powiadomieni:
                <v-chip v-for="(item, index) of notifiedUsers" :key="item.id" class="ma-2" close
                  @click:close="removeNotify(index)" small>
                  <v-icon left small>
                    {{ item.channel ? item.channel : 0 | channelIcon }}
                  </v-icon>
                  {{ item.full_name }}
                </v-chip>
              </v-col>
              <v-col cols="12" v-if="canceledBy.length" class="black--text font-weight-medium">
                Odwołał:
                <v-chip v-for="item of canceledBy" :key="item.id" class="ma-2" small>
                  <v-icon left small>
                    {{ item.channel ? item.channel : 0 | channelIcon }}
                  </v-icon>
                  {{ item.full_name }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
        </material-card>
      </v-col> -->
      <!-- </v-row>

    <v-row > -->
      <v-col cols="12" :lg="patrolColumns" class="d-flex" v-for="task of patrolTasks" :key="task.id">
        <patrol-card class="fill-height" :site="event.site" :task="task" @update:task="addTask"
          :readOnly="!canCloseTask"></patrol-card>
      </v-col>
    </v-row>

    <v-timeline dense v-if="false">
      <!-- group -->
      <v-slide-x-transition group>
        <task-timeline-item v-for="task in timeline" :key="task.id" :task="task">
        </task-timeline-item>
      </v-slide-x-transition>
    </v-timeline>
  </v-container>
</template>

<script>
import EventService from "@/services/event.service"
import SiteService from "@/services/site.service"
import TaskService from "@/services/task.service"
import { UserTaskType, foundUsersDialog, notifyUsersDialog, setPaymentDialog } from "@/store/modules/task"
import UserTask from "@/store/modules/task"
import paidCard from "@/components/PaidCard.vue"
// import EventEditor from '../components/EventEditor.vue'
import sendPatrolCard from "@/components/SendPatrolCard.vue"
import notifyDialog from "@/components/NotifyDialog.vue"
import cancelTaskDialog from "@/components/CancelTaskDialog.vue"
import closeTaskDialog from '@/components/CloseTaskDialog.vue'
// import SiteAddress from "../components/SiteAddress.vue"
import { sync } from 'vuex-pathify'
import { parseJSON, format } from "date-fns"
import DownloadEventsDialog from "@/components/DownloadEventsDialog.vue"

export default {
  name: "EventDetailsView",

  metaInfo() {
    return {
      title: `${this.event.site.code_name} - ${this.event.code.text}`,
      meta: [
        {
          name: "description",
          content: `${this.event.site.code_name} - ${this.event.code.text}`
        },
        {
          property: "og:title",
          content: `og:title = ${this.event.site.code_name} - ${this.event.code.text}`
        },
        { property: "og:site_name", content: "og:site_name = Monpatrol" },
        {
          property: "og:description",
          content: `og:site_name = ${this.event.site.code_name} - ${this.event.code.text}`
        },
        { property: "og:type", content: "og:type = profile" },
        {
          property: "og:url",
          content: "https://monpatrol.pl"
        },
        {
          property: "og:image",
          content: ""
        }
      ]
    }
  },
  beforeMount() {
    const eventId = this.$route.params.id
    EventService.fetch(eventId).then(response => {
      this.event = response.data
      this.refreshNotes()
      this.addAll(response.data.tasks)
      this.timer = setInterval(this.refresh, 5000)
    })
  },
  beforeDestroy() {
    this.cancelAutoUpdate()
  },
  data: () => ({
    notes: [],
    comment: null,
    timer: "",
    modifiedAt: null,

    event: {
      site: {},
      code: {
        text: ""
      },
      details: {},
      createdAt: ""
    },
    dispatcherTask: null,
    tasks: [],
    items: []
  }),
  filters: {},
  computed: {
    user: sync('user.profile'),
    patrolColumns() {
      return this.patrolTasks.length > 1 ? 6 : 6
    },

    eventReported() {
      return this.event.details?.reported_by?.full_name ?? null
    },
    eventCreated() {
      return this.event.details?.created_by?.full_name ?? null
    },
    title() {
      return this.dispatcherTask?.description
    },
    eventInfo() {
      return this.event.details?.info ?? null
    },
    dispatcherTaskMessage() {
      return this.dispatcherTask?.details?.status?.message ?? null
    },
    dispatcherTaskComment() {
      return this.dispatcherTask?.details?.status?.comment ?? null
    },
    status() {
      return this.dispatcherTask?.details.status
    },
    statusText() {
      return null //this.dispatcherTask?.details.status?.message
    },
    canEditEvent() {
      return this.canCloseTask && !UserTask.isReadOnly(this.dispatcherTask, this.user) && this.user.permissions.event.change
    },
    canCloseTask() {
      return this.dispatcherTask && this.dispatcherTask.closed_at == null
    },
    // dispatcherTask() {
    //   return this.tasks
    //     .filter(task => {
    //       return task.task_type == UserTaskType.DISPATCHER
    //     })
    //     .find(() => true)
    // },
    created_by: function () {
      try {
        return this.dispatcherTask.created_by
      } catch {
        return null
      }
    },
    reported_by: function () {
      try {
        return this.dispatcherTask.details.reported_by
      } catch {
        return null
      }
    },
    canceledBy: function () {
      return this.dispatcherTask?.details.canceled ?? []
    },
    patrolTasks: function () {
      return this.tasks.filter(task => task.task_type == UserTaskType.PATROL)
    },
    notifiedUsers: function () {
      return this.dispatcherTask?.details?.notified ?? []
    },
    visibleEvent: function () {
      return this.event != null
    },
    visibleDetails: function () {
      return this.event.details.info != undefined
    },
    timeline() {
      return this.items.slice().reverse()
    },
    eventDate() {
      if (this.event.details.date) {
        return this.event.details.date
      } else {
        return this.event.created_at
      }
    }
  },
  methods: {
    canOpenTask(task) {
      return task == null || (!this.canCloseTask && !UserTask.isReadOnly(task, this.user))
    },
    onEditEvent() {
      this.$router.push({
        name: "Edit Event",
        params: {
          eventId: this.event.id,
          event: this.event,
          expert: true
        }
      })
    },
    async onDeleteEvent() {
      const confirmCode = `${this.event.site.code_name} ${this.event.site.name}`
      const result = await this.$dialog.prompt({
        text: `Dla potwierdzenia wpisz: ${confirmCode}`,
        value: "",
        title: "Czy na pewno chcesz usunąć zdarzenie?",
        rules: [],
        actions: {
          false: "Anuluj",
          true: {
            color: "critical",
            text: "OK"
          }
        }
      })
      if (result === false || result === undefined || result != confirmCode) {
        return
      }
      EventService.delete(this.event).then(res => {
        if (res.status == 204) {
          this.$router.push({ name: "Events" })
        }
      })
    },
    removeNotify(index) {
      const task = this.dispatcherTask
      const user = this.notifiedUsers[index]
      TaskService.deleteNotifiedUser(this.event.site.id, task.id, user).then(
        () => {
          this.notifiedUsers.splice(index, 1)
          this.refresh()
        }
      )
    },
    async notifyUsers() {
      const args = {
        context: this,
        notifyDialog: notifyDialog,
        taskService: TaskService,
        siteService: SiteService,
        event: this.event,
        task: this.dispatcherTask,
      }
      await notifyUsersDialog(args).then(() => {
        this.refresh()
      })
    },
    async foundUsers() {
      const args = {
        context: this,
        foundDialog: notifyDialog,
        taskService: TaskService,
        siteService: SiteService,
        site: this.event.site,
        task: this.dispatcherTask,
      }
      await foundUsersDialog(args).then(() => {
        this.refresh()
      })
    },
    async cancelTask() {
      const dialogInstance = await this.$dialog.show(cancelTaskDialog, {
        message: this.dispatcherTask.details.status.message,
        comment: this.dispatcherTask.details.status.comment,
        users: new Promise(resolve => {
          SiteService.fetchMembers(this.event.site.id).then(result =>
            resolve(result.map(member => member.user))
          )
        })
      })
      dialogInstance.waitForResult = true
      dialogInstance.wait().then(result => {
        if (!result) {
          return
        }
        const task = this.dispatcherTask
        TaskService.cancelTask(
          this.event.site.id,
          task.id,
          result.message,
          result.comment,
          result.user
        ).then(res => this.addTask(res.data))
      })
    },
    cancelAutoUpdate() {
      clearInterval(this.timer)
    },
    startDispatcherTask() {
      if (this.dispatcherTask) {
        TaskService.restartTask({
          siteId: this.event.site.id,
          taskId: this.dispatcherTask.id,
          message: this.dispatcherTask.details.status.message,
          comment: this.dispatcherTask.details.status.comment
        }).then(res => this.addTask(res.data))
      } else {
        //nowy
        TaskService.create(this.event.id, {
          assignees: [],
          task_type: UserTaskType.DISPATCHER,
          description: ""
        }).then(res => this.addTask(res.data))
      }
    },
    addLineToComment() {
      if (this.comment == null) {
        this.comment = ""
      }
      this.comment += "\n"
    },
    addComment() {
      if (!this.comment) {
        return
      }
      if (this.comment.trim().length == 0) {
        return
      }
      var lines = this.comment.split('\n');
      var comment = this.comment
      var more = null
      if (lines.length > 1) {
        comment = lines[0]
        lines.shift()
        more = lines.join('\n')
      }
      TaskService.addComment(this.event.site.id, this.dispatcherTask.id, comment, more).then(res => {
        this.addTask(res.data)
        this.comment = null
      })

      // this.dispatcherTask.details.history.push({
      //   created_at: new Date().toISOString(),
      //   label_id: 300,
      //   comment: this.comment
      // })
      // this.comment = null
    },
    async closeAllTasks() {
      const result = await this.$dialog.show(closeTaskDialog, {
        event: this.event,
        message: this.dispatcherTask.details.status.message,
        comment: this.dispatcherTask.details.status.comment,
        waitForResult: true,
        actions: {
          false: 'Anuluj',
          true: {
            color: 'success',
            text: 'OK',
          },
        },
      });
      if (result === false || result === undefined) {
        return;
      }
      const task = this.dispatcherTask;
      console.log('result :>> ', result, task);
      var promises = result.payments
        .filter(
          (payment) => (payment.task?.details?.cost?.token ?? 0) != payment.token
        )
        .map((payment) => {
          console.log(
            'payment promis (set cost):>> ',
            payment.task.details?.cost?.token,
            payment.token
          );
          TaskService.setCost(this.event.site.id, payment.task.id, payment.token);
        });

      if (result.note) {
        let note = {
          siteId: this.event.site.id,
          category: result.note.id,
          beginningAt: new Date(task.created_at),
          expiresAt: result.note.expiresAt ? new Date(result.note.expiresAt) : null,
          message: result.message,
          reportedBy: null
        };
        console.log('note :>> ', note);
        promises.push(SiteService.createNote(note));
      }
      Promise.all(promises).then(() => {
        return TaskService.closeTask(this.event.site.id, task.id, result.message, result.comment)
      }).then(res => this.addTask(res.data));
    },
    async startPatrolTask() {
      const instance = this
      const dialogInstance = await this.$dialog.show(sendPatrolCard)
      console.log("send patrol", dialogInstance)
      dialogInstance.waitForResult = true
      dialogInstance.wait().then(patrols => {
        console.log("Patrol", patrols)
        if (!patrols) {
          return
        }
        for (const patrol of patrols.selected) {
          TaskService.create(this.event.id, {
            assignees: [{ user_id: patrol.id }],
            task_type: UserTaskType.PATROL,
            description: patrols.description,
            details: {
              options: { can_closed: patrols.canClose }
            },
            parent_id: this.dispatcherTask.id
          }).then(() => {
            instance.refresh()
          })
        }
      })
    },

    addTask(task) {
      if (task == null) {
        return
      }
      this.addAll([task])
    },
    addAll(tasks) {
      if (!tasks) {
        return
      }
      //   const items = []
      for (const task of tasks) {
        this.addAll(task.subtasks)
        if (task.task_type == UserTaskType.DISPATCHER) {
          this.dispatcherTask = task
        }
        var index = this.tasks.findIndex(t => t.id == task.id) //ES
        // var index = this.tasks.map(task => task.id).indexOf(task.id);
        if (~index) {
          this.tasks.splice(index, 1, task)
        } else {
          index = this.tasks.length
          this.tasks.push(task)
        }
        task.index = index
        if (this.modifiedAt == null || this.modifiedAt < task.modified_at) {
          this.modifiedAt = task.modified_at
        }
      }
      this.event.tasks = this.tasks
    },
    async setPayment() {
      setPaymentDialog(this, paidCard, TaskService, this.dispatcherTask, this.event.site).then(res => {
        this.addTask(res)
      })
    },
    refreshNotes() {
      const args = {
        conditions: [`date=${parseJSON(this.event.created_at).toISOString()}`]
      }
      SiteService.getNotes(this.event.site.id, args).then(response => {
        this.notes = response.data
      })
    },
    refresh() {
      if (this.modifiedAt == null) {
        this.modifiedAt = new Date(0).toISOString()
      }
      this.refreshNotes()
      TaskService.fetch(
        this.event.id,
        "modified_after=" + this.modifiedAt
      ).then(response => {
        if (response.data.length > 0) {
          this.addAll(response.data)
        }
      })
    },
    async onDownload() {
      const result = await this.$dialog.show(DownloadEventsDialog, {
        title: "Zdarzenie",
        waitForResult: true,
      });
      if (result === false || result === undefined) {
        return;
      }
      console.log('this.result :>> ', result);
      var query = {
        id: `${this.event.id}`,
        ...result,
      }
      EventService.download(
        this.event.site.id,
        query
      ).then(response => {
        console.log("ok", response.data)
        const blob = new Blob([response.data], {
          type: "application/pdf;charset=utf-8;"
        })
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = `raport_${format(
          new Date(),
          "yyyMMddHHmmss"
        )}.pdf`
        link.click()
        URL.revokeObjectURL(link.href)
      })
    }
  }
}
</script>

<style scoped>
.note {
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  font-family: inherit;
}
</style>
